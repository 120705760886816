@font-face {
  font-family: "Futura PT Light";
  src: url("../assets/fonts/futura-pt/FuturaPTLight.otf");
  font-display: fallback;
}

@font-face {
  font-family: "Futura PT Book";
  src: url("../assets/fonts/futura-pt/FuturaPTBook.otf");
  font-display: fallback;
}

@font-face {
  font-family: "Futura PT Medium";
  src: url("../assets/fonts/futura-pt/FuturaPTMedium.otf");
  font-display: fallback;
}

@font-face {
  font-family: "Futura PT Heavy";
  src: url("../assets/fonts/futura-pt/FuturaPTHeavy.otf");
  font-display: fallback;
}

@font-face {
  font-family: "Futura PT Bold";
  src: url("../assets/fonts/futura-pt/FuturaPTBold.otf");
  font-display: fallback;
}

@font-face {
  font-family: "NotoSerifSC-Light";
  src: url("../assets/fonts/noto_serif_sc/NotoSerifSC-Light.otf");
  font-display: fallback;
}

@font-face {
  font-family: "NotoSerifSC-Regular";
  src: url("../assets/fonts/noto_serif_sc/NotoSerifSC-Regular.otf");
  font-display: fallback;
}

@font-face {
  font-family: "NotoSerifSC-Medium";
  src: url("../assets/fonts/noto_serif_sc/NotoSerifSC-Medium.otf");
  font-display: fallback;
}

@font-face {
  font-family: "NotoSerifSC-SemiBold";
  src: url("../assets/fonts/noto_serif_sc/NotoSerifSC-SemiBold.otf");
  font-display: fallback;
}
